import ServicesData from './../data/services-data.json';

import { buildingAboutAnimation, consultingAboutAnimation, estimateAboutAnimation, heroAboutAnimation, initialAnimation, analysis, animation } from "../animation";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import {useParams, Link} from 'react-router-dom'

export default function Service() {
    const params = useParams();
    const {slug} = params;
    console.warn(slug)
    const data = ServicesData.find(item => item.slug === slug);
    // const subServices = data.subServices;
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area breadcrumb-banner">
                <div className="container">
                    <div
                        className="section-heading heading-left"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={300}
                    >
                        <h1 className="title h2">{data.Service}</h1>
                        <p className="text-justify">{data.info}</p>
                    </div>
                    <div
                        className="banner-thumbnail thumbnail-4"
                        data-sal="slide-up"
                        data-sal-duration={1000}
                        data-sal-delay={400}
                    >
                        <Player
                            autoplay
                            speed={1.5}
                            loop
                            src={consultingAboutAnimation}
                            style={{ height: "936px", width: "666px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={[
                                    "play",
                                    "repeat",
                                    "frame",
                                    "debug",
                                    "snapshot",
                                    "background"
                                ]}
                            />
                        </Player>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="../assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="../assets/media/others/bubble-22.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
        </>
    )}