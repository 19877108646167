import ServicesData from './../data/services-data.json';
export default function Navbar() {
    const location = window.location.origin
    const logoLight = window.location.origin + '/assets/media/logo.png'
    const logoDark = window.location.origin + '/assets/media/logo.png'
    const logoSticky = window.location.origin + '/assets/media/logo.png'
    return (
        <>

            {/*=====================================*/}
            {/*=        Header Area Start       	=*/}
            {/*=====================================*/}
            <header className="header axil-header header-style-1">
                <div id="axil-sticky-placeholder" />
                <div className="axil-mainmenu">
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                                <a href="/">
                                    <img
                                        className="light-version-logo"
                                        src={logoLight}
                                        alt="logo"
                                    />
                                    <span className="test logo-text-light">GammaVerse</span>
                                </a>
                                {/* <a href="/">
                                    <img
                                    className="dark-version-logo"
                                    src={logoDark}
                                    alt="logo"
                                    />
                                    <span className="test logo-text-dark">GammaVerse</span>
                                </a> */}
                                <a href="/" className="logo-text-sticky">
                                    <img
                                        className="sticky-logo"
                                        src={logoSticky}
                                        alt="logo"
                                    />
                                </a>

                            </div>
                            <div className="header-main-nav">
                                {/* Start Mainmanu Nav */}
                                <nav className="mainmenu-nav" id="mobilemenu-popup">
                                    <div className="d-block d-lg-none">
                                        <div className="mobile-nav-header">
                                            <div className="mobile-nav-logo">
                                                <a href="/">
                                                    <img
                                                        className="light-mode"
                                                        src={logoSticky}
                                                        alt="Site Logo"
                                                    />
                                                    <img
                                                        className="dark-mode"
                                                        src={logoDark}
                                                        alt="Site Logo"
                                                    />
                                                </a>
                                            </div>
                                            <button
                                                className="mobile-menu-close"
                                                data-bs-dismiss="offcanvas"
                                            >
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <ul className="mainmenu">
                                        <li>
                                            <a href="/about">About</a>
                                        </li>
                                        {/* <li>
                                            <a href="/product">Product</a>
                                        </li> */}
                                        {/* <li>
                                            <a href="/services">Services</a>
                                        </li>
                                        <li>
                                            <a href="/product-engineering">Product Engineering</a>
                                        </li> */}
                                        <li className="menu-item-has-children">
                                            <a href="/services">IT Consulting & Services</a>
                                            <ul className="axil-submenu">
                                                {
                                                     ServicesData.map(ServicesData => {
                                                        const subLink = "/service/"+ServicesData.slug;
                                                        return (
                                                            <li><a href={subLink}>{ServicesData.Service}</a></li>
                                                        )}
                                                )}
                                                
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="/product">Product</a>
                                        </li>
                                        <li>
                                            <a href="/contact">Contact</a>
                                        </li>
                                    </ul>
                                </nav>
                                {/* End Mainmanu Nav */}
                            </div>
                            <div className="header-action">
                                <ul className="list-unstyled">
                                    <li className="sidemenu-btn d-lg-block d-none">
                                        <button
                                            className="btn-wrap"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasMenuRight"
                                        >
                                            <span />
                                            <span />
                                            <span />
                                        </button>
                                    </li>
                                    <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                        <button
                                            className="btn-wrap"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#mobilemenu-popup"
                                        >
                                            <span />
                                            <span />
                                            <span />
                                        </button>
                                    </li>
                                    <li className="my_switcher d-block d-lg-none">
                                        <ul>
                                            <li title="Light Mode">
                                                <a
                                                    href="#"
                                                    className="setColor light"
                                                    data-theme="light"
                                                >
                                                    <i className="fal fa-lightbulb-on" />
                                                </a>
                                            </li>
                                            <li title="Dark Mode">
                                                <a
                                                    href="#"
                                                    className="setColor dark"
                                                    data-theme="dark"
                                                >
                                                    <i className="fas fa-moon" />
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
