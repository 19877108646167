import ServicesData from './../data/services-data.json';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import {  heroAboutAnimation, heroAnimationOne, heroAnimationTwo, heroAnimationThree, analysisAboutAnimation, animation } from "../animation";
export default function Services() {
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area breadcrumb-banner">
                <div className="container">
                    <div
                        className="section-heading heading-left"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={300}
                    >
                        <h1 className="title h2">Empowering Your Business with Innovative and Scalable IT Solutions</h1>
                        <p className="text-justify">At Gammaverse Private Limited, we provide comprehensive IT consulting and solutions to help businesses thrive in the digital age. From custom software development and cybersecurity to cloud services and data analytics, our expert team is dedicated to delivering innovative, scalable, and secure IT solutions tailored to meet your unique needs.
                        </p>
                    </div>
                    <div
                        className="banner-thumbnail thumbnail-4"
                        data-sal="slide-up"
                        data-sal-duration={1000}
                        data-sal-delay={400}
                    >
                        <Player
                            autoplay
                            speed={1.5}
                            loop
                            src={animation}
                            style={{ height: "936px", width: "666px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={[
                                    "play",
                                    "repeat",
                                    "frame",
                                    "debug",
                                    "snapshot",
                                    "background"
                                ]}
                            />
                        </Player>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="../assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="../assets/media/others/bubble-22.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
            <section className="section section-padding expert-counterup-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Experts in field</span>
                                <h2>What makes us special?</h2>
                                <p className="mb--50" align="justify">
                                    <strong>Expertise:</strong> A team of experienced professionals with deep knowledge in various IT domains.<br/>
                                    <strong>Customization: </strong>Tailored solutions to meet the unique needs of each client.<br/>
                                    <strong>Innovation:</strong> Commitment to using the latest technologies to drive business success.<br/>
                                    <strong>Reliability:</strong> Trusted partner with a proven track record of delivering high-quality services.<br/>
                                </p>
                                <a
                                    href="/contact"
                                    className="axil-btn btn-large btn-fill-primary"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="row" id="no-equal-gallery">
                                <div
                                    className="col-sm-6 no-equal-item"
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                    data-sal-delay={100}
                                >
                                    <div className="counterup-progress counterup-style-2 active">
                                        <div className="icon">
                                            <img
                                                className="dark-icon"
                                                src="assets/media/icon/icon-10.png"
                                                alt="Icon"
                                            />
                                            <img
                                                className="light-icon"
                                                src="assets/media/icon/icon-7.png"
                                                alt="Icon"
                                            />
                                        </div>
                                        <div className="count-number h2">
                                            <span className="number count" data-count={15}>
                                                15
                                            </span>
                                            <span className="symbol"> +</span>
                                        </div>
                                        <h6 className="title">Years of operation</h6>
                                    </div>
                                </div>
                                <div
                                    className="col-sm-6 mt--90 mt_md--0 no-equal-item"
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                    data-sal-delay={200}
                                >
                                    <div className="counterup-progress counterup-style-2">
                                        <div className="icon">
                                            <img
                                                className="dark-icon"
                                                src="assets/media/icon/icon-11.png"
                                                alt="Icon"
                                            />
                                            <img
                                                className="light-icon"
                                                src="assets/media/icon/icon-8.png"
                                                alt="Icon"
                                            />
                                        </div>
                                        <div className="count-number h2">
                                            <span className="number count" data-count={100}>
                                                100
                                            </span>
                                            <span className="symbol"> +</span>
                                        </div>
                                        <h6 className="title">Projects deliverd</h6>
                                    </div>
                                </div>
                                <div
                                    className="col-sm-6 no-equal-item"
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                    data-sal-delay={300}
                                >
                                    <div className="counterup-progress counterup-style-2">
                                        <div className="icon">
                                            <img
                                                className="dark-icon"
                                                src="assets/media/icon/icon-12.png"
                                                alt="Icon"
                                            />
                                            <img
                                                className="light-icon"
                                                src="assets/media/icon/icon-9.png"
                                                alt="Icon"
                                            />
                                        </div>
                                        <div className="count-number h2">
                                            <span className="number count" data-count={20}>
                                                20
                                            </span>
                                            <span className="symbol"> +</span>
                                        </div>
                                        <h6 className="title">Specialist</h6>
                                    </div>
                                </div>
                                <div
                                    className="col-sm-6 no-equal-item"
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                    data-sal-delay={400}
                                >
                                    <div className="counterup-progress counterup-style-2">
                                        <div className="icon">
                                            <img
                                                className="dark-icon"
                                                src="assets/media/icon/icon-7.png"
                                                alt="Icon"
                                            />
                                            <img
                                                className="light-icon"
                                                src="assets/media/icon/icon-7.png"
                                                alt="Icon"
                                            />
                                        </div>
                                        <div className="count-number h2">
                                            <span className="number count" data-count={50}>
                                                50
                                            </span>
                                            <span className="symbol"> +</span>
                                        </div>
                                        <h6 className="title">Happy Clients</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section-padding bg-color-light">
                <div className="container">
                    <div className="section-heading heading-left">
                        <span className="subtitle">What We Can Do For You</span>
                        <h2 className="title">
                            Services we can <br /> help you with
                        </h2>
                    </div>
                    <div className="row">
                        {
                            ServicesData.map(ServicesData => {
                                const subLink = "/service/"+ServicesData.slug;
                                return (
                                    
                                    <div
                                    className="col-lg-4 col-md-6"
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                    data-sal-delay={100}
                                >
                                    <div className="services-grid service-style-2">
                                        <div className="thumbnail">
                                            <img src="assets/media/icon/icon-1.png" alt="icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">

                                                <a href={subLink}>{ServicesData.Service}</a>
                                            </h5>
                                            <p>{ServicesData.info}</p>
                                            <a href={subLink} className="more-btn">
                                                Find out more
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            
        </>
    );
}