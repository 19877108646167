import { Player, Controls } from '@lottiefiles/react-lottie-player';
import animation from "./../download/shamal.json";
export default function Test() {
    return (<>
        <div style={{backgroundColor: "#FF0000", height:'50%', width:'50%'}}>
            <Player autoplay speed={1.5} loop src={animation} >  
                <Controls visible={false}
                    buttons={[
                        "play",
                        "repeat",
                        "frame",
                        "debug",
                        "snapshot",
                        "background"
                    ]}
                />
            </Player>
        </div>
    </>)
}