export default function Home() {
    return(
        <>
            {/*=====================================*/}
            {/*=        Banner Area Start         =*/}
            {/*=====================================*/}
            <section className="banner banner-style-1">
                <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                    <div
                        className="banner-content"
                        data-sal="slide-up"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <h1 className="title">
                        Build beautiful website &amp; mobile apps.
                        </h1>
                        <span className="subtitle">
                        Create live segments and target the right people for messages
                        based on their behaviors.
                        </span>
                        <a
                        href="/contact"
                        className="axil-btn btn-fill-primary btn-large"
                        >
                        Get Started
                        </a>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="banner-thumbnail">
                        <div
                        className="large-thumb"
                        data-sal="zoom-in"
                        data-sal-duration={800}
                        data-sal-delay={300}
                        >
                        <img src="assets/media/banner/window.png" alt="Laptop" />
                        </div>
                        <div
                        className="large-thumb-2"
                        data-sal="slide-left"
                        data-sal-duration={800}
                        data-sal-delay={800}
                        >
                        <img src="assets/media/banner/laptop-poses.png" alt="Laptop" />
                        </div>
                        <ul className="list-unstyled shape-group">
                        <li
                            className="shape shape-1"
                            data-sal="slide-left"
                            data-sal-duration={500}
                            data-sal-delay={800}
                        >
                            <img src="assets/media/banner/chat-group.png" alt="chat" />
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="list-unstyled shape-group-21">
                <li
                    className="shape shape-1"
                    data-sal="slide-down"
                    data-sal-duration={500}
                    data-sal-delay={100}
                >
                    <img src="assets/media/others/bubble-39.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-2"
                    data-sal="zoom-in"
                    data-sal-duration={800}
                    data-sal-delay={500}
                >
                    <img src="assets/media/others/bubble-38.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-3"
                    data-sal="slide-left"
                    data-sal-duration={500}
                    data-sal-delay={700}
                >
                    <img src="assets/media/others/bubble-14.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-4"
                    data-sal="slide-left"
                    data-sal-duration={500}
                    data-sal-delay={700}
                >
                    <img src="assets/media/others/bubble-14.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-5"
                    data-sal="slide-left"
                    data-sal-duration={500}
                    data-sal-delay={700}
                >
                    <img src="assets/media/others/bubble-14.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-6"
                    data-sal="slide-left"
                    data-sal-duration={500}
                    data-sal-delay={700}
                >
                    <img src="assets/media/others/bubble-40.png" alt="Bubble" />
                </li>
                <li
                    className="shape shape-7"
                    data-sal="slide-left"
                    data-sal-duration={500}
                    data-sal-delay={700}
                >
                    <img src="assets/media/others/bubble-41.png" alt="Bubble" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Service Area Start         =*/}
            {/*=====================================*/}
            <section className="section section-padding-2 bg-color-dark">
                <div className="container">
                <div className="section-heading heading-light-left">
                    <span className="subtitle">What We Can Do For You</span>
                    <h2 className="title">Services we can help you with</h2>
                    <p className="opacity-50">
                    Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate
                    augue sed eu imperdiet.
                    </p>
                </div>
                <div className="row">
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={100}
                    >
                    <div className="services-grid active">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-1.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-design.html">Design</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-design.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={200}
                    >
                    <div className="services-grid">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-2.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-development.html">Development</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-development.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={300}
                    >
                    <div className="services-grid">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-3.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-marketing.html">Online marketing</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-marketing.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={100}
                    >
                    <div className="services-grid">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-4.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-business.html">Business</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-business.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={200}
                    >
                    <div className="services-grid">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-5.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-technology.html">Technology</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-technology.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-4 col-md-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={300}
                    >
                    <div className="services-grid">
                        <div className="thumbnail">
                        <img src="assets/media/icon/icon-6.png" alt="icon" />
                        </div>
                        <div className="content">
                        <h5 className="title">
                            {" "}
                            <a href="service-content-strategy.html">Content strategy</a>
                        </h5>
                        <p>
                            Simply drag and drop photos and videos into your workspace to
                            automatically add them to your Collab Cloud library.
                        </p>
                        <a href="service-content-strategy.html" className="more-btn">
                            Find out more
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1">
                    <img src="assets/media/others/line-9.png" alt="Circle" />
                </li>
                <li className="shape shape-2">
                    <img src="assets/media/others/bubble-42.png" alt="Circle" />
                </li>
                <li className="shape shape-3">
                    <img src="assets/media/others/bubble-43.png" alt="Circle" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=       Case Study Area Start       =*/}
            {/*=====================================*/}
            <section className="section section-padding-equal case-study-featured-area">
                <div className="container">
                <div className="row">
                    <div
                    className="col-xl-5 col-lg-6"
                    data-sal="slide-right"
                    data-sal-duration={800}
                    >
                    <div
                        className="slick-slider slick-dot-nav featured-content"
                        data-slick='{"infinite": true,"arrows": false, "dots": true, "slidesToShow": 1, "asNavFor": ".featured-thumbnail"}'
                    >
                        <div className="slick-slide">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                            <span className="subtitle">Featured Case Study</span>
                            <h2 className="title">
                                Logo, identity &amp; web design for Uber
                            </h2>
                            <p>
                                Donec metus lorem, vulputate at sapien sit amet, auctor
                                iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus
                                velit. Aliquam tristique libero at dui sodales, et placerat
                                orci lobortis.
                            </p>
                            <p>
                                Maecenas ipsum neque, elementum id dignissim et, imperdiet
                                vitae mauris.
                            </p>
                            <a
                                href="single-case-study.html"
                                className="axil-btn btn-fill-primary btn-large"
                            >
                                Read Case Study
                            </a>
                            </div>
                            <div className="case-study-counterup">
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={15}>
                                    15
                                </span>
                                <span className="symbol">%</span>
                                </h2>
                                <span className="counter-title">ROI increase</span>
                            </div>
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={60}>
                                    60
                                </span>
                                <span className="symbol">k</span>
                                </h2>
                                <span className="counter-title">
                                Monthly website visits
                                </span>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="slick-slide">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                            <span className="subtitle">Featured Case Study</span>
                            <h2 className="title">Website and web Development, Design</h2>
                            <p>
                                Donec metus lorem, vulputate at sapien sit amet, auctor
                                iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus
                                velit. Aliquam tristique libero at dui sodales, et placerat
                                orci lobortis.
                            </p>
                            <p>
                                Maecenas ipsum neque, elementum id dignissim et, imperdiet
                                vitae mauris.
                            </p>
                            <a
                                href="single-case-study.html"
                                className="axil-btn btn-fill-primary btn-large"
                            >
                                Read Case Study
                            </a>
                            </div>
                            <div className="case-study-counterup">
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={20}>
                                    20
                                </span>
                                <span className="symbol">%</span>
                                </h2>
                                <span className="counter-title">ROI increase</span>
                            </div>
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={60}>
                                    60
                                </span>
                                <span className="symbol">k</span>
                                </h2>
                                <span className="counter-title">
                                Monthly website visits
                                </span>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="slick-slide">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                            <span className="subtitle">Featured Case Study</span>
                            <h2 className="title">
                                Branding, website &amp; digital marketing for Apple
                            </h2>
                            <p>
                                Donec metus lorem, vulputate at sapien sit amet, auctor
                                iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus
                                velit. Aliquam tristique libero at dui sodales, et placerat
                                orci lobortis.
                            </p>
                            <p>
                                Maecenas ipsum neque, elementum id dignissim et, imperdiet
                                vitae mauris.
                            </p>
                            <a
                                href="single-case-study.html"
                                className="axil-btn btn-fill-primary btn-large"
                            >
                                Read Case Study
                            </a>
                            </div>
                            <div className="case-study-counterup">
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={15}>
                                    15
                                </span>
                                <span className="symbol">%</span>
                                </h2>
                                <span className="counter-title">ROI increase</span>
                            </div>
                            <div className="single-counterup">
                                <h2 className="count-number">
                                <span className="number count" data-count={60}>
                                    60
                                </span>
                                <span className="symbol">k</span>
                                </h2>
                                <span className="counter-title">
                                Monthly website visits
                                </span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-7 col-lg-6 d-none d-lg-block"
                    data-sal="slide-left"
                    data-sal-duration={800}
                    >
                    <div
                        className="slick-slider slick-dot-nav featured-thumbnail"
                        data-slick='{"infinite": true,"arrows": false, "dots": false, "fade": true, "slidesToShow": 1, "asNavFor": ".featured-content"}'
                    >
                        <div className="slick-slide">
                        <div className="case-study-featured-thumb">
                            <img src="assets/media/others/case-study-4.png" alt="travel" />
                        </div>
                        </div>
                        <div className="slick-slide">
                        <div className="case-study-featured-thumb">
                            <img src="assets/media/others/case-study-5.png" alt="travel" />
                        </div>
                        </div>
                        <div className="slick-slide">
                        <div className="case-study-featured-thumb">
                            <img src="assets/media/others/case-study-6.png" alt="travel" />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/*=====================================*/}
            {/*=        About Area Start         =*/}
            {/*=====================================*/}
            <section className="section section-padding-equal bg-color-light">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6" data-sal="slide-up" data-sal-duration={800}>
                    <div className="about-us">
                        <div className="section-heading heading-left mb-0">
                        <span className="subtitle">About Us</span>
                        <h2 className="title mb--40">
                            We do design, code &amp; develop.
                        </h2>
                        <p>
                            Nulla et velit gravida, facilisis quam a, molestie ante. Mauris
                            placerat suscipit dui, eget maximus tellus blandit a. Praesent
                            non tellus sed ligula commodo blandit in et mauris. Quisque
                            efficitur ipsum ut dolor molestie pellentesque.{" "}
                        </p>
                        <p>
                            Nulla pharetra hendrerit mi quis dapibus. Quisque luctus, tortor
                            a venenatis fermentum, est lacus feugiat nisl, id pharetra odio
                            enim eget libero.{" "}
                        </p>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-xl-5 col-lg-6 offset-xl-1"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={100}
                    >
                    <div className="contact-form-box">
                        <h3 className="title">Get a free Keystroke quote now</h3>
                        <form
                        method="POST"
                        action="#"
                        className="axil-contact-form"
                        >
                        <div className="form-group">
                            <label>Name</label>
                            <input
                            type="text"
                            className="form-control"
                            name="contact-name"
                            placeholder="John Smith"
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                            type="email"
                            className="form-control"
                            name="contact-email"
                            placeholder="example@mail.com"
                            />
                        </div>
                        <div className="form-group mb--40">
                            <label>Phone</label>
                            <input
                            type="tel"
                            className="form-control"
                            name="contact-company"
                            placeholder={+123456789}
                            />
                        </div>
                        <div className="form-group">
                            <button
                            type="submit"
                            className="axil-btn btn-fill-primary btn-fluid btn-primary"
                            name="submit-btn"
                            >
                            Get Free Quote
                            </button>
                        </div>
                        <input
                            type="hidden"
                            className="form-control"
                            name="contact-message"
                            defaultValue="Null"
                        />
                        </form>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1">
                    <img src="assets/media/others/bubble-7.png" alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src="assets/media/others/line-4.png" alt="line" />
                </li>
                <li className="shape shape-3">
                    <img src="assets/media/others/line-5.png" alt="line" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Project Area Start         =*/}
            {/*=====================================*/}
            <section className="section section-padding-2">
                <div className="container">
                <div className="section-heading heading-left mb--40">
                    <span className="subtitle">Our Project</span>
                    <h2 className="title">
                    Some of our <br /> finest work.
                    </h2>
                </div>
                <div className="axil-isotope-wrapper">
                    <div className="isotope-button isotope-project-btn">
                    <button data-filter="*" className="is-checked">
                        <span className="filter-text">All Works</span>
                    </button>
                    <button data-filter=".branding">
                        <span className="filter-text">Branding</span>
                    </button>
                    <button data-filter=".mobile">
                        <span className="filter-text">Mobile</span>
                    </button>
                    </div>
                    <div className="row row-35 isotope-list">
                    <div className="col-md-6 project branding">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-1.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Creative Agency</a>
                            </h4>
                            <span className="subtitle">Full Branding, Website, App</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 project mobile">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-2.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Digital Marketing</a>
                            </h4>
                            <span className="subtitle">Logo, Website &amp; Mobile App</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 project branding">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-3.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Digital Agency</a>
                            </h4>
                            <span className="subtitle">Website, UI/UX</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 project mobile">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-4.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Plan Management</a>
                            </h4>
                            <span className="subtitle">Branding, Website, IOS App</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 project branding">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-5.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Social Engagement</a>
                            </h4>
                            <span className="subtitle">Design, Development</span>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 project mobile">
                        <div className="project-grid">
                        <div className="thumbnail">
                            <a href="single-portfolio.html">
                            <img src="assets/media/project/project-6.png" alt="project" />
                            </a>
                        </div>
                        <div className="content">
                            <h4 className="title">
                            <a href="single-portfolio.html">Web Application</a>
                            </h4>
                            <span className="subtitle">Logo, Webapp, App</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1">
                    <img src="assets/media/others/circle-2.png" alt="circle" />
                </li>
                <li className="shape shape-2">
                    <img src="assets/media/others/bubble-2.png" alt="Line" />
                </li>
                <li className="shape shape-3">
                    <img src="assets/media/others/bubble-1.png" alt="Line" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Counter Up Area Start      =*/}
            {/*=====================================*/}
            <section className="section section-padding bg-color-dark">
                <div className="container">
                <div className="section-heading heading-light">
                    <span className="subtitle">Featured Case Study</span>
                    <h2 className="title">Design startup movement</h2>
                    <p>
                    In vel varius turpis, non dictum sem. Aenean in efficitur ipsum, in
                    egestas ipsum. Mauris in mi ac tellus.
                    </p>
                </div>
                <div className="row">
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={100}
                    >
                    <div className="counterup-progress active">
                        <div className="icon">
                        <img src="assets/media/icon/icon-7.png" alt="Apple" />
                        </div>
                        <div className="count-number h2">
                        <span className="number count" data-count={15}>
                            15
                        </span>
                        <span className="symbol">+</span>
                        </div>
                        <h6 className="title">Years of operation</h6>
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={200}
                    >
                    <div className="counterup-progress">
                        <div className="icon">
                        <img src="assets/media/icon/icon-8.png" alt="Apple" />
                        </div>
                        <div className="count-number h2">
                        <span className="number count" data-count={360}>
                            360
                        </span>
                        <span className="symbol">+</span>
                        </div>
                        <h6 className="title">Projects deliverd</h6>
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={300}
                    >
                    <div className="counterup-progress">
                        <div className="icon">
                        <img src="assets/media/icon/icon-9.png" alt="Apple" />
                        </div>
                        <div className="count-number h2">
                        <span className="number count" data-count={600}>
                            600
                        </span>
                        <span className="symbol">+</span>
                        </div>
                        <h6 className="title">Specialist</h6>
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={400}
                    >
                    <div className="counterup-progress">
                        <div className="icon">
                        <img src="assets/media/icon/apple.png" alt="Apple" />
                        </div>
                        <div className="count-number h2">
                        <span className="number count" data-count={64}>
                            64
                        </span>
                        <span className="symbol">+</span>
                        </div>
                        <h6 className="title">Years of operation</h6>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                {/* <li class="shape shape-1"><img src="assets/media/others/line-9.png" alt="Circle"></li> */}
                <li className="shape shape-2">
                    <img src="assets/media/others/bubble-42.png" alt="Circle" />
                </li>
                <li className="shape shape-3">
                    <img src="assets/media/others/bubble-43.png" alt="Circle" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Testimonial Area Start      =*/}
            {/*=====================================*/}
            <section className="section section-padding">
                <div className="container">
                <div className="section-heading heading-left">
                    <span className="subtitle">Testimonial</span>
                    <h2 className="title">From getting started</h2>
                    <p>
                    Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate
                    augue sed eu leo eget risus imperdiet.
                    </p>
                </div>
                <div className="row">
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={1000}
                    data-sal-delay={100}
                    >
                    <div className="testimonial-grid active">
                        <span className="social-media">
                        <img src="assets/media/icon/yelp-2.png" alt="Yelp" />
                        </span>
                        <p>
                        “ Donec metus lorem, vulputate at sapien sit amet, auctor iaculis
                        lorem. In vel hendrerit nisi. Vestibulum eget risus velit. ”
                        </p>
                        <div className="author-info">
                        <div className="thumb">
                            <img
                            src="assets/media/testimonial/testimonial-1.png"
                            alt="Google Review"
                            />
                        </div>
                        <div className="content">
                            <span className="name">Darrell Steward</span>
                            <span className="designation">Executive Chairman</span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={1000}
                    data-sal-delay={200}
                    >
                    <div className="testimonial-grid">
                        <span className="social-media">
                        <img src="assets/media/icon/google-2.png" alt="google" />
                        </span>
                        <p>
                        “ Donec metus lorem, vulputate at sapien sit amet, auctor iaculis
                        lorem. In vel hendrerit nisi. Vestibulum eget risus velit. ”
                        </p>
                        <div className="author-info">
                        <div className="thumb">
                            <img
                            src="assets/media/testimonial/testimonial-2.png"
                            alt="Google Review"
                            />
                        </div>
                        <div className="content">
                            <span className="name">Savannah Nguyen</span>
                            <span className="designation">Executive Chairman</span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={1000}
                    data-sal-delay={300}
                    >
                    <div className="testimonial-grid">
                        <span className="social-media">
                        <img src="assets/media/icon/fb-2.png" alt="Facebook" />
                        </span>
                        <p>
                        “ Donec metus lorem, vulputate at sapien sit amet, auctor iaculis
                        lorem. In vel hendrerit nisi. Vestibulum eget risus velit. ”
                        </p>
                        <div className="author-info">
                        <div className="thumb">
                            <img
                            src="assets/media/testimonial/testimonial-3.png"
                            alt="Google Review"
                            />
                        </div>
                        <div className="content">
                            <span className="name">Floyd Miles</span>
                            <span className="designation">Executive Chairman</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="shape-group-4 list-unstyled">
                <li className="shape-1">
                    <img src="assets/media/others/bubble-1.png" alt="Bubble" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Pricing Area Start       	=*/}
            {/*=====================================*/}
            <section className="section bg-color-light section-padding">
                <div className="container">
                <div className="section-heading mb-0">
                    <span className="subtitle">Pricing Plan</span>
                    <h2 className="title">Find the Right Plan.</h2>
                    <p>
                    Flexible pricing options for freelancers <br /> and design teams.
                    </p>
                </div>
                <div className="pricing-billing-duration">
                    <ul>
                    <li className="nav-item">
                        <button
                        className="nav-link active"
                        id="yearly-plan-btn"
                        type="button"
                        >
                        Billed yearly
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" id="monthly-plan-btn" type="button">
                        Billed monthly
                        </button>
                    </li>
                    </ul>
                </div>
                <div className="row">
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={100}
                    >
                    <div className="pricing-table active">
                        <div className="pricing-header">
                        <h3 className="title">Professional</h3>
                        <span className="subtitle">A beautiful, simple website</span>
                        <div className="price-wrap">
                            <div className="yearly-pricing">
                            <span className="amount">$119.99</span>
                            <span className="duration">/yearly</span>
                            </div>
                            <div className="monthly-pricing">
                            <span className="amount">$19.00</span>
                            <span className="duration">/monthly</span>
                            </div>
                        </div>
                        <div className="pricing-btn">
                            <a href="#" className="axil-btn btn-large btn-borderd">
                            Get Started Today
                            </a>
                        </div>
                        </div>
                        <div className="pricing-body">
                        <ul className="list-unstyled">
                            <li>10 Pages Responsive Website</li>
                            <li>5 PPC Campaigns</li>
                            <li>10 SEO Keywords</li>
                            <li>5 Facebook Camplaigns</li>
                            <li>2 Video Camplaigns</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={200}
                    >
                    <div className="pricing-table">
                        <div className="pricing-header">
                        <h3 className="title">Standard</h3>
                        <span className="subtitle">Small Team</span>
                        <div className="price-wrap">
                            <div className="yearly-pricing">
                            <span className="amount">$219.99</span>
                            <span className="duration">/yearly</span>
                            </div>
                            <div className="monthly-pricing">
                            <span className="amount">$29.00</span>
                            <span className="duration">/monthly</span>
                            </div>
                        </div>
                        <div className="pricing-btn">
                            <a href="#" className="axil-btn btn-large btn-borderd">
                            Get Started Today
                            </a>
                        </div>
                        </div>
                        <div className="pricing-body">
                        <ul className="list-unstyled">
                            <li>50 Pages Responsive Website</li>
                            <li>Unlimited PPC Campaigns</li>
                            <li>Unlimited SEO Keywords</li>
                            <li>Unlimited Facebook Camplaigns</li>
                            <li>Unlimited Video Camplaigns</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div
                    className="col-lg-4"
                    data-sal="slide-up"
                    data-sal-duration={800}
                    data-sal-delay={300}
                    >
                    <div className="pricing-table">
                        <div className="pricing-header">
                        <h3 className="title">Ultimate</h3>
                        <span className="subtitle">Large Team</span>
                        <div className="price-wrap">
                            <div className="yearly-pricing">
                            <span className="amount">$559.99</span>
                            <span className="duration">/yearly</span>
                            </div>
                            <div className="monthly-pricing">
                            <span className="amount">$59.00</span>
                            <span className="duration">/monthly</span>
                            </div>
                        </div>
                        <div className="pricing-btn">
                            <a href="#" className="axil-btn btn-large btn-borderd">
                            Get Started Today
                            </a>
                        </div>
                        </div>
                        <div className="pricing-body">
                        <ul className="list-unstyled">
                            <li>10 Pages Responsive Website</li>
                            <li>5 PPC Campaigns</li>
                            <li>10 SEO Keywords</li>
                            <li>5 Facebook Camplaigns</li>
                            <li>2 Video Camplaigns</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <ul className="shape-group-3 list-unstyled">
                <li className="shape shape-1">
                    <img src="assets/media/others/line-1.png" alt="shape" />
                </li>
                <li className="shape shape-2">
                    <img src="assets/media/others/bubble-4.png" alt="shape" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Brand Area Start       	=*/}
            {/*=====================================*/}
            <section className="section section-padding bg-color-dark">
                <div className="container">
                <div className="section-heading heading-light-left">
                    <span className="subtitle">Top Clients</span>
                    <h2 className="title">We’ve built solutions for...</h2>
                    <p>
                    Design anything from simple icons to fully featured websites and
                    applications.
                    </p>
                </div>
                <div className="row">
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    >
                    <div className="brand-grid active">
                        <img src="assets/media/brand/brand-1.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={100}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-2.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={200}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-3.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={300}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-4.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={400}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-5.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={500}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-6.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={600}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-7.png" alt="Brand" />
                    </div>
                    </div>
                    <div
                    className="col-lg-3 col-6"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={700}
                    >
                    <div className="brand-grid">
                        <img src="assets/media/brand/brand-8.png" alt="Brand" />
                    </div>
                    </div>
                </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1">
                    <img src="assets/media/others/line-9.png" alt="Circle" />
                </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=        Blog Area Start       	    =*/}
            {/*=====================================*/}
            <section className="section section-padding-equal">
                <div className="container">
                    <div className="section-heading">
                    <span className="subtitle">What's Going On</span>
                    <h2 className="title">Latest stories</h2>
                    <p>
                        News From GammaVerse And Around The World Of Web Design And Complete
                        Solution of Online Digital Marketing{" "}
                    </p>
                    </div>
                    <div className="row g-0">
                    <div
                        className="col-xl-6"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <div className="blog-list active">
                        <div className="post-thumbnail">
                            <a href="single-blog-2.html">
                            <img src="assets/media/blog/blog-1.png" alt="Blog Post" />
                            </a>
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                            <a href="single-blog-2.html">
                                How To Use a Remarketing Strategy To Get More
                            </a>
                            </h5>
                            <p>
                            Demand generation is a constant struggle for any business. Each
                            marketing strategy you employ has...
                            </p>
                            <a href="single-blog-2.html" className="more-btn">
                            Learn more
                            <i className="far fa-angle-right" />
                            </a>
                        </div>
                        </div>
                    </div>
                    <div
                        className="col-xl-6"
                        data-sal="slide-left"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <div className="blog-list border-start">
                        <div className="post-thumbnail">
                            <a href="single-blog-3.html">
                            <img src="assets/media/blog/blog-2.png" alt="Blog Post" />
                            </a>
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                            <a href="single-blog-3.html">
                                SEO Statistics You Should Know in 2021
                            </a>
                            </h5>
                            <p>
                            Organic search has the potential to capture more than 40 percent
                            of your gross revenue...
                            </p>
                            <a href="single-blog-3.html" className="more-btn">
                            Learn more
                            <i className="far fa-angle-right" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <ul className="shape-group-1 list-unstyled">
                    <li className="shape shape-1">
                    <img src="assets/media/others/bubble-1.png" alt="bubble" />
                    </li>
                    <li className="shape shape-2">
                    <img src="assets/media/others/line-1.png" alt="bubble" />
                    </li>
                    <li className="shape shape-3">
                    <img src="assets/media/others/line-2.png" alt="bubble" />
                    </li>
                    <li className="shape shape-4">
                    <img src="assets/media/others/bubble-2.png" alt="bubble" />
                    </li>
                </ul>
            </section>

            {/*=====================================*/}
            {/*=     Call To Action Area Start     =*/}
            {/*=====================================*/}
            <section className="section call-to-action-area">
                <div className="container">
                <div className="call-to-action">
                    <div className="section-heading heading-light">
                    <span className="subtitle">Let's Work Together</span>
                    <h2 className="title">Need a successful project?</h2>
                    <a href="contact.html" className="axil-btn btn-large btn-fill-white">
                        Estimate Project
                    </a>
                    </div>
                    <div className="thumbnail">
                    <div
                        className="larg-thumb"
                        data-sal="zoom-in"
                        data-sal-duration={600}
                        data-sal-delay={100}
                    >
                        <img
                        className="paralax-image"
                        src="assets/media/others/chat-group.png"
                        alt="Chat"
                        />
                    </div>
                    <ul className="list-unstyled small-thumb">
                        <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={400}
                        >
                        <img
                            className="paralax-image"
                            src="assets/media/others/laptop-poses.png"
                            alt="Laptop"
                        />
                        </li>
                        <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={800}
                        data-sal-delay={300}
                        >
                        <img
                            className="paralax-image"
                            src="assets/media/others/bill-pay.png"
                            alt="Bill"
                        />
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <ul className="list-unstyled shape-group-9">
                <li className="shape shape-1">
                    <img src="assets/media/others/bubble-12.png" alt="Comments" />
                </li>
                <li className="shape shape-2">
                    <img src="assets/media/others/bubble-16.png" alt="Comments" />
                </li>
                <li className="shape shape-3">
                    <img src="assets/media/others/bubble-13.png" alt="Comments" />
                </li>
                <li className="shape shape-4">
                    <img src="assets/media/others/bubble-14.png" alt="Comments" />
                </li>
                <li className="shape shape-5">
                    <img src="assets/media/others/bubble-16.png" alt="Comments" />
                </li>
                <li className="shape shape-6">
                    <img src="assets/media/others/bubble-15.png" alt="Comments" />
                </li>
                <li className="shape shape-7">
                    <img src="assets/media/others/bubble-16.png" alt="Comments" />
                </li>
                </ul>
            </section>
        </>

    );
}