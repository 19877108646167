export default function Header() {
    return(
        <>
             {/*[if lte IE 9]>
            <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
            <![endif]*/}
            <a href="#main-wrapper" id="backto-top" className="back-to-top">
                <i className="far fa-angle-double-up" />
            </a>
            {/* Preloader Start Here */}
            <div id="preloader" />
            {/* Preloader End Here */}
            <div className="my_switcher d-none d-lg-block">
                <ul>
                <li title="Light Mode" dangerouslySetInnerHTML={{__html: '<a href="javascript:void(0)" class="setColor light" data-theme="light" > <i class="fal fa-lightbulb-on" /></a>'}}>
                </li>
                <li title="Dark Mode" dangerouslySetInnerHTML={{__html: '<a href="javascript:void(0)" class="setColor dark" data-theme="dark" > <i class="fas fa-moon" /></a>'}}>
                </li>
                </ul>
            </div>
        </>
    );
}